<template>
    <transition mode="out-in"
                @enter="openOverlay"
                @leave="closeOverlay"
                :css="false">
        <div :class="{ overlay: true, [card.view]: true }" v-show="open">
            <circle-progress ref="countdown" class="progress" v-if="mode === 'auto'" @ended="close"></circle-progress>
            <img src="/static/img/cross.svg" alt="" class="close" @touchstart="close" @pointerup="close" />
            <h1>{{ card.name }}</h1>
            <p class="description">{{ card.description }}</p>
            <div>
                <div class="card">
                    <img :src="card.ticketVisual" alt="" class="visual" />
                    <div class="tag"><img :src="'/static/img/usps/' + card.usp1 + '.svg'" :alt="card.usp1"></div>
                    <div class="tag"><img :src="'/static/img/usps/' + card.usp2 + '.svg'" :alt="card.usp2"></div>
                    <div class="tag"><img :src="'/static/img/usps/' + card.usp3 + '.svg'" :alt="card.usp3"></div>
                </div>
            </div>
            <div class="qr">
                <img :src="'https://chart.googleapis.com/chart?chs=500x500&cht=qr&chl=' + card.url + '&choe=UTF-8'" alt="">
            </div>
            <p class="cta">Koop je Kraslot bij de kassa</p>
        </div>
    </transition>
</template>

<style lang="scss">
    // Unimportant css code, can be removed at will:
</style>

<script>
    import gsap from 'gsap';
    import CircleProgress from './CircleProgress';
    export default {
        name: 'Overlay',
        components: {
            CircleProgress
        },
        props: {
            open: {
                type: Boolean,
                default: false
            },
            card: {
                type: Object,
                default: () => { return {}; }
            }
        },

        computed: {
            mode()
            {
                return this.$store.getters['mode'];
            }
        },

        mounted()
        {
            this.tl = gsap.timeline({ paused: true });
            this.tl.from( '.overlay>*', 0.3, { scale: 0, autoAlpha: 0, stagger: 0.1 });
            this.tl.from( '.overlay .card .tag', 0.3, { scaleY: 0, autoAlpha: 0, stagger: 0.1 });
            this.timeout = setTimeout( () =>
            {
                this.close();
            }, 50000 );
        },

        beforeDestroy()
        {
            clearTimeout( this.timeout );
        },

        methods: {
            close()
            {
                this.$store.dispatch( 'close' );
            },
            openOverlay( el, done )
            {
                if( this.mode === 'auto' )
                {
                    this.$refs.countdown.start();
                }
                this.tl.timeScale( 1 ).play().then( done );
            },
            closeOverlay( el, done )
            {
                this.tl.timeScale( 4 ).reverse().then( done );
            }
        }
    };
</script>
