<template>
    <svg class="progress" width="120" height="120" viewBox="0 0 120 120">
        <g>
            <circle class="progress__meter" cx="60" cy="60" r="54" stroke-width="12" />
            <circle ref="progressValue" class="progress__value" cx="60" cy="60" r="54" stroke-width="12" />
        </g>
        <text x="60" y="60" fill="white" font-size="35px" text-anchor="middle">{{ countdown }}</text>
    </svg>
</template>

<style scoped lang="scss">

    .progress {
        text {
            font-family: 'CodecCold';
            color: white;
        }
    }

    .progress__meter,
    .progress__value {
        fill: none;
    }

    .progress__meter {
        stroke: transparent;
    }

    .progress__value {
        stroke: white;
        stroke-linecap: round;
    }
</style>

<script>
    export default {
        props: {
            autotime: {
                type: Number,
                default: 15
            }
        },
        data()
        {
            return {
                now: new Date().getTime(),
                started: new Date().getTime(),
                radius: 54
            };
        },

        computed: {
            countdown()
            {
                return this.autotime - Math.round( this.autotime * this.progress );
            },
            circumference()
            {
                return 2 * Math.PI * this.radius;
            },
            progress()
            {
                return Math.min( 1, Math.max( 1 / ( this.autotime * 1000 ) * ( this.now - this.started ), 0 ) );
            }
        },

        watch: {
            progress()
            {
                let offset = this.circumference * ( 1 - this.progress );
                this.$refs.progressValue.style.strokeDashoffset = offset;
                if( this.progress >= 1 )
                {
                    this.$emit( 'ended' );
                }
            }
        },

        mounted()
        {
            this.$refs.progressValue.style.strokeDasharray = `${this.circumference} ${this.circumference}`;
            this.start();
        },

        destroyed()
        {
            clearInterval( this.interval );
        },

        methods: {
            start()
            {
                this.started = new Date().getTime();
                clearInterval( this.interval );
                this.interval = setInterval( () =>
                {
                    this.now = new Date().getTime();
                }, 1000 / 60 );
            }
        }
    };
</script>
