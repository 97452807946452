import Vue from 'vue';
import Vuex from 'vuex';

import scratchcards from '../data/scratchcards.json';

Vue.use( Vuex );
require( 'es6-promise' ).polyfill();

export default new Vuex.Store({
    strict: true,

    modules: {
    },

    state: {
        scratchcards,
        card: undefined,
        mode: 'auto'
    },

    getters: {
        scratchcards: ({ scratchcards }) => scratchcards,
        card: ({ card }) => card,
        mode: ({ mode }) => mode
    },

    mutations: {
        showCard: ( state, payload ) =>
        {
            state.card = payload;
        },
        close: ( state, payload ) =>
        {
            state.card = undefined;
        },
        mode: ( state, payload ) =>
        {
            state.mode = payload;
        }
    },

    actions: {
        showCard: ( context, payload ) =>
        {
            context.commit( 'showCard', payload );
        },
        close: ( context, payload ) =>
        {
            context.commit( 'close', payload );
        },
        mode: ( context, payload ) =>
        {
            context.commit( 'mode', payload );
        },
        random: ( context, payload ) =>
        {
            context.commit( 'showCard', context.getters.scratchcards[ Math.floor( ( context.getters.scratchcards.length - 1 ) * Math.random() ) ] );
        }
    }
});
