<template>
    <div class="main-layout" :style="'background-color: ' + color + ';'">
        <img src="/static/img/logo.svg" alt="" class="logo">
        <div class="content">
            <slot></slot>
        </div>
        <y-overlay :open="!!card" :card="card"></y-overlay>
        <img src="/static/img/footer.png" alt="" class="footer">
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
    import YOverlay from '../components/Overlay.vue';
    export default {
        components: {
            YOverlay
        },
        computed: {
            card()
            {
                return this.$store.getters['card'];
            },
            color()
            {
                console.log( this.$store.getters['card'] );
                return this.$store.getters['card'] ? this.$store.getters['card'].color : '#ec1e8f';
            }
        }
    };
</script>
